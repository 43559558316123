/* CSS for CRIStin */
@import "../../../../../../vrtx/groups/design-uio1/src/resources/css/util.less";
@import "../../../../../../lib/uio1/profile/css/util.less";
#hidnav a[href="#bottomnav"] {
  display: none;
}
:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

html {
  background: none repeat-x fixed left top @color-light !important;
}
body {
  background: none repeat-y center top !important;
}
.not-for-ansatte.header-search-collapsable {
  .header-search-expand {
    background-color: @color-secondary;
    color: @color-light;
  }
  .header-search-expand:hover,
  .header-search-expand:focus {
    background-color: @color-neutral !important;
    text-decoration: none;
  }
}

#nettstedskart,
#sitemap {
  font-size: 0.92em;
  position: absolute;
  right: 140px;
  top: 114px;
  right: 0px;
  a {
    color: @color-neutral;
  }
}

.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white):not(.searchsubmit):not(.submit-button-link):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-button !important;
    color: @color-light !important;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      color: @color-light;
      background-color: @color-secondary !important;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }

  .mainButtonsBg(@color-button, @color-secondary);
  .mainButtonsFontColor(@color-light, @color-light);

  #head-wrapper {
    background: @color-light; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(
      right,
      @color-neutral--air,
      @color-light
    ); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
      right,
      @color-neutral--air,
      @color-light
    ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
      right,
      @color-neutral--air,
      @color-light
    ); /* For Firefox 3.6 to 15 */
    background: linear-gradient(
      right,
      @color-neutral--air,
      @color-light
    ); /* Standard syntax */
    #head {
      height: 165px;
      .uio-app-name {
        display: none;
      }
      .head-menu > ul > li a,
      .head-menu > .vrtx-login-manage-component > a {
        color: @color-neutral !important;
      }
      .head-menu {
        .vrtx-login-manage-component .vrtx-dropdown-link {
          background: transparent
            url("../images/language-dropdown-toggle-black.png") no-repeat scroll
            100% 53%;
        }
      }
      #header {
        height: 165px;
        #header-logo {
          background-size: 90% auto;
          max-width: 500px;
          padding-top: 14px;
        }
      }
    }
  }
  #bottomnav {
    display: none;
  }
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-orange.svg") no-repeat 0 3px !important;
}

#main .vrtx-back a::before {
  background: url("../images/icon-chevron-back-orange.svg") no-repeat 0 0.2ex;
  background-size: auto 100%;
  content: " ";
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-orange.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-orange.svg");
  }
}

.vrtx-introduction {
  font-family: arial, sans-serif;
}

#uiodoc {
  border: none !important;
}

#globalnav {
  background-color: @color-primary;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  ul,
  li {
    background-color: @color-primary;
    color: @color-light;
  }
  li {
    a,
    span {
      background-color: @color-primary;
      color: @color-light;
    }
    a:hover,
    a:focus {
      background-color: @color-secondary !important;
      text-decoration: underline !important;
    }
  }
  li.vrtx-active-item a {
    background-color: @color-light !important;
    color: @color-neutral--dark;
  }

  #left-main {
    .vrtx-breadcrumb-menu {
      li > span,
      li > a {
        color: @color-secondary;
      }
    }
  }
}

ul.vrtx-uri-menu li.parent-folder a {
  font-weight: bold;
}
#privacy-policy-link-wrapper.info-link-wrapper {
  margin: 0px 0 30px 0;
  padding: 0;
  background-color: #707070;
}
#privacy-policy-link-wrapper .info-link {
  background-color: #707070;
  padding: 8px 36px 11px 36px;
}

#footer-wrapper {
  background-color: @color-primary;
  color: @color-light;
  padding-bottom: 10px;
  h2 {
    color: @color-light;
  }
  // a:not(.vrtx-login-manage-logout) {
  //   color: @color-light !important;
  // }
  #footers {
    .vrtx-dropdown-wrapper {
      li a {
        text-decoration: underline;
        &:hover {
          background-color: #2771bb;
          -webkit-box-shadow: 0 0 0 2px #2771bb;
          -moz-box-shadow: 0 0 0 2px #2771bb;
          box-shadow: 0 0 0 2px #2771bb;
          color: @color-light;
          text-decoration: none;
        }
      }
    }
 
    .col-3-5 {
      width: 66%;
    }
    .col-2-5 {
      width: 33%;
      &.responsible-login .content {
        margin-left: 0;
      }
    }
    padding-bottom: 130px;
  }
  .sikt {
    margin-top: 90px;
    margin-left: 292px;
    position: absolute;
    a {
      display: block;
      margin-top: 5px;
    }
    .kunnskapstekst,
    .kunnskapstekst-eng {
      white-space: nowrap;
      margin-left: 48px;
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  #head-wrapper #header {
    height: 140px !important;
    position: relative;
    #header-logo {
      background: url(../images/logo-responsive.png) no-repeat 0 0 transparent;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 3%;
      transform: translate(0, -50%);
      img {
        visibility: hidden;
      }
    }
    #nettstedskart {
      display: none;
    }
    .header-text {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-left: 20px;
      margin-top: 96px;
      position: absolute;
    }
  }
  .grid-container {
    .vrtx-frontpage-box {
      h2 {
        margin-left: 0 !important;
      }
      .vrtx-frontpage-box-picture {
        position: relative !important;
      }
      .vrtx-box-content {
        margin-left: 0 !important;
      }
      .vrtx-more.abs-more-link {
        padding-top: 0 !important;
        position: relative !important;
      }
    }
  }
  #menu-wrapper .menu {
    li a.toggle-navigation,
    li a.toggle-search {
      background-color: @color-primary;
    }
  }
  #footer-wrapper {
    .sikt {
      margin-left: 0;
      margin-top: 0;
      position: relative;
      .sikt-logo {
        display: block;
        margin-left: 0;
      }
      .kunnskapstekst,
      .kunnskapstekst-eng {
        margin-left: 0;
        width: auto;
        font-size: 0.9em;
        white-space: pre-wrap;
      }
    }

    #footers .col-2-5 {
      width: 100%;
    }
  }
}

/*------------------------------------*\
# colour
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #323232; //#dadada;
@color-secondary: #424242; //#0150A1;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #636363;
@color-neutral--air: #f1f1f1;
@color-neutral--light: #dfdfdf;
@color-border: #cccccc;
@color-button: #d44511; //#ed5823;
// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@color-link: #0b5a9d;
